.DocumentNotFound {

    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.13);
    border: solid 1px var(--peacock-blue);
    background-color: #ffffff;

    display:block;
    align-items: center;
    text-align: center;
    padding: 20px;

    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
}

h1 {
    color: var(--slate-grey);

    margin-bottom: 10px;
}