/* =============================================================================
   Variables

   ========================================================================== */
:root {
  --light-color-text: #fff;
  --peacock-blue: #005cab;
  --colors-royal: #006699;
  --colors-gray: #333333;
  --greeny-blue: #38b39a;
  --slate-grey: #636466;
  --colors-navy: #17405a;
  --turquoise-blue: #00a2b8;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
